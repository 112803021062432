<template>
	<div>
		<card v-if="! hasCompetitorData"
			  :headline="$t('competitors.activate.title')">
			<p>{{ $t('competitors.activate.line1') }}</p>
			<p>{{ $t('competitors.activate.line2') }}</p>
			<p>{{ $t('competitors.activate.line3') }}</p>

			<button type="button"
					@click="activate"
					class="btn btn-primary">
				<i v-if="activating"
				   class="fa fa-spinner fa-spin me-2" />
				<span>{{ $t('competitors.activate.button') }}</span>
			</button>
		</card>

		<competitor-page v-else />
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import CustomerService from '@/services/_app/customers/CustomerService'
const CompetitorPage = () => import('./CompetitorPage')

export default {
	data: () => ({
		activating: false
	}),

	computed: {
		...mapGetters('customer', {
			customer: 'getCustomer'
		}),
		hasCompetitorData() {
			return !! this.customer.competitorData
		}
	},
	methods: {
		...mapActions('identity', {
			loadIdentity: 'loadIdentity'
		}),
		async activate() {
			if (this.activating) {
				return
			}
			this.activating = true
			await CustomerService.put(this.customer.id, {
				competitorData: true
			})
			await this.loadIdentity()
		}
	},
	components: {CompetitorPage}
}
</script>
